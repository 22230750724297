import "../styles/ExperienceCard.css";

export default function ExperienceCard({
  company,
  role,
  duration,
  featuresList,
}) {
  return (
    <div className="experience--card">
      <div className="experience--card--header">
        <div className="experience--card--header--main">
          <h1 className="experience--card--company">{company}</h1>
          <h2 className="experience--card--role">{role}</h2>
        </div>
        <h3 className="experience--card--duration">{duration}</h3>
      </div>
      <div className="experience--card--body">
        <ul className="experience--card--list">{featuresList}</ul>
      </div>
    </div>
  );
}
