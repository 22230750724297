import "../styles/Button.css";
import { Link } from "react-router-dom";

export default function RouteButton({ text, link, selected }) {
  return (
    <Link className={`button--main ${selected ? "selected" : ""}`} to={link}>
      {text}
    </Link>
  );
}
