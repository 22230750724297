import "../styles/Links.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithubSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";

export default function Links() {
  return (
    <div className="links">
      <a href="http://www.github.com/HemantAntony">
        <FontAwesomeIcon
          className="links--link"
          icon={faGithubSquare}
          size="4x"
        />
      </a>
      <a href="http://www.linkedin.com/in/hemant-antony/">
        <FontAwesomeIcon className="links--link" icon={faLinkedin} size="4x" />
      </a>
      <a href="mailto:hemantantony@gmail.com">
        <FontAwesomeIcon
          className="links--link"
          icon={faEnvelopeSquare}
          size="4x"
        />
      </a>
    </div>
  );
}
