import React from "react";
import DesktopNavBar from "./DesktopNavBar";
import PhoneNavBar from "./PhoneNavBar";
import PhoneNavBarMenu from "./PhoneNavBarMenu";

export default function NavBar({ selected }) {
  const [hamburgerMenuToggle, setHamburgerMenuToggle] = React.useState(false);

  return (
    <nav>
      <DesktopNavBar selected={selected} />
      <PhoneNavBarMenu
        active={hamburgerMenuToggle}
        closeNavBarMenu={() => setHamburgerMenuToggle(false)}
      />
      <PhoneNavBar
        hamburgerMenuToggled={hamburgerMenuToggle}
        hamburgerClicked={() => {
          setHamburgerMenuToggle((prev) => !prev);
        }}
      />
    </nav>
  );
}
