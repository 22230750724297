import "../styles/DesktopNavBar.css";
import { Link } from "react-router-dom";
import "../styles/PhoneNavBarMenu.css";

export default function PhoneNavBarMenu({ active, closeNavBarMenu }) {
  return (
    <div className={`phone--navbar--menu ${active ? "active" : ""}`}>
      <ul className="phone--navbar--menu--items">
        <li className="phone--navbar--menu--item">
          <Link
            className="phone--navbar--menu--link"
            to="/"
            onClick={closeNavBarMenu}
          >
            Home
          </Link>
        </li>
        <li className="phone--navbar--menu--item">
          <Link
            className="phone--navbar--menu--link"
            to="/experience"
            onClick={closeNavBarMenu}
          >
            Experience
          </Link>
        </li>
        <li className="phone--navbar--menu--item">
          <Link
            className="phone--navbar--menu--link"
            to="/projects"
            onClick={closeNavBarMenu}
          >
            Projects
          </Link>
        </li>
        <li className="phone--navbar--menu--item">
          <Link
            className="phone--navbar--menu--link"
            to="/skills"
            onClick={closeNavBarMenu}
          >
            Skills
          </Link>
        </li>
        <li className="phone--navbar--menu--item">
          <a
            className="phone--navbar--menu--link"
            href="https://drive.google.com/file/d/1f5nc0SrEN-0MU8EBusmfykr6jlYsIR4a/view?usp=sharing"
            onClick={closeNavBarMenu}
          >
            Resume
          </a>
        </li>
      </ul>
    </div>
  );
}
