import "../styles/PhoneNavBar.css";
import { useNavigate } from "react-router-dom";

export default function PhoneNavBar({
  hamburgerMenuToggled,
  hamburgerClicked,
}) {
  const navigate = useNavigate();
  return (
    <div className="phone--navbar">
      <img
        src="logo.png"
        className="phone--navbar--logo"
        onClick={() => navigate("/")}
      ></img>
      <div className="phone--navbar--hamburger--positioner">
        <div
          className={`phone--navbar--hamburger ${
            hamburgerMenuToggled ? "active" : ""
          }`}
          onClick={hamburgerClicked}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
  //   return <h1>Testing</h1>;
}
