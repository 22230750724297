import ExperienceCard from "./ExperienceCard";

export default function AcademicExperience() {
  return (
    <div className="experience--academic">
      <ExperienceCard
        company="TLE"
        role="Competitive Programming Lead"
        duration="Jun 2023 - NOW"
        featuresList={[
          <li>
            Leading a <b>Competitive Programming</b> team
          </li>,
          <li>
            Have taught programming to newcomers in the Software Engineering
            industry
          </li>,
          <li>
            Have helped conduct <b>coding competitions</b>
          </li>,
        ]}
      />
      <ExperienceCard
        company="Excel Tech Team"
        role="CS Tech Team Core Member"
        duration="Oct 2023 - NOW"
        featuresList={[
          <li>
            <b>Head of event</b> of a game created in relation to the tech-fest
          </li>,
          <li>Analysis on the number of participants playing the game</li>,
        ]}
      />
    </div>
  );
}
