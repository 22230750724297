import "../styles/Projects.css";
import NavBar from "../components/NavBar";
import ProjectCard from "../components/ProjectCard";
import ProjectsData from "../data/projects.json";
import Links from "../components/Links";

export default function Projects() {
  const projects = ProjectsData.map((project) => (
    <ProjectCard
      image={project.image}
      title={project.title}
      desc={project.desc}
      link={project.link}
    />
  ));

  return (
    <div className="projects">
      <NavBar selected="Projects" />
      <div className="projects--list">{projects}</div>
      <Links />
    </div>
  );
}
