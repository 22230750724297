import "../styles/SkillsCard.css";

export default function SkillsCard({ title, skills }) {
  const skillElements = skills.map((skill) => (
    <img
      className={`skills--card--image ${skill}`}
      src={`./images/skills/${skill}.png`}
    ></img>
  ));
  return (
    <div className="skills--card">
      <h1 className="skills--card--title">{title}</h1>
      <div className="skills--card--list">{skillElements}</div>
    </div>
  );
}
