import "../styles/Home.css";
import NavBar from "../components/NavBar";
import RouteButton from "../components/RouteButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithubSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";

export default function Home() {
  return (
    <div className="home">
      <NavBar selected="Home" />
      <div className="home--main">
        <div className="home--text">
          <h2 className="home--pretitle">Hello, my name is</h2>
          <h1 className="home--title">Hemant Antony</h1>
          <img className="home--image" src="./images/photo.png"></img>
          <p className="home--desc">
            I’m a Software Engineer from Kerala, India. 🇮🇳 <br />
            I have worked with a lot of technologies including Qt, Unity and
            React etc. 🎮 <br />
            I have 7+ years of programming experience. 💻 <br />
            I’m passionate about projects with unique problems, extra points if
            it helps make the world a better place 🌏 ❤️
          </p>
        </div>

        <div className="home--buttons">
          <RouteButton text="My Experience" link="/experience" />
          <RouteButton text="My Projects" link="/projects" />
          <RouteButton text="My Skills" link="/skills" />
        </div>

        <div className="home--links">
          <a href="http://www.github.com/HemantAntony">
            <FontAwesomeIcon
              className="home--link"
              icon={faGithubSquare}
              size="4x"
            />
          </a>
          <a href="http://www.linkedin.com/in/hemant-antony/">
            <FontAwesomeIcon
              className="home--link"
              icon={faLinkedin}
              size="4x"
            />
          </a>
          <a href="mailto:hemantantony@gmail.com">
            <FontAwesomeIcon
              className="home--link"
              icon={faEnvelopeSquare}
              size="4x"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
