import "../styles/Skills.css";
import NavBar from "../components/NavBar";
import SkillsCard from "../components/SkillsCard";
import SkillsData from "../data/skills.json";
import Links from "../components/Links";

export default function Skills() {
  const skills = SkillsData.map((skill) => (
    <SkillsCard title={skill.title} skills={skill.skills} />
  ));

  return (
    <div>
      <NavBar selected="Skills" />
      <div className="skills--list">{skills}</div>
      <Links />
    </div>
  );
}
