import "../styles/DesktopNavBar.css";
import { Link } from "react-router-dom";

export default function DesktopNavBar({ selected }) {
  return (
    <ul className="navbar">
      <li className="navbar--item">
        <Link
          className={`navbar--link ${selected === "Home" ? "selected" : ""}`}
          to="/"
        >
          Home
        </Link>
      </li>
      <li className="navbar--item">
        <Link
          className={`navbar--link ${
            selected === "Experience" ? "selected" : ""
          }`}
          to="/experience"
        >
          Experience
        </Link>
      </li>
      <li className="navbar--item">
        <Link
          className={`navbar--link ${
            selected === "Projects" ? "selected" : ""
          }`}
          to="/projects"
        >
          Projects
        </Link>
      </li>
      <li className="navbar--item">
        <Link
          className={`navbar--link ${selected === "Skills" ? "selected" : ""}`}
          to="/skills"
        >
          Skills
        </Link>
      </li>
      <li className="navbar--item">
        <a
          className="navbar--link"
          href="https://drive.google.com/file/d/1f5nc0SrEN-0MU8EBusmfykr6jlYsIR4a/view?usp=sharing"
        >
          Resume
        </a>
      </li>
    </ul>
  );
}
