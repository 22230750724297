import React from "react";
import "../styles/Experience.css";
import Button from "../components/Button";
import NavBar from "../components/NavBar";
import ProfessionalExperience from "../components/ProfessionalExperience";
import AcademicExperience from "../components/AcademicExperience";
import Links from "../components/Links";

export default function Experience() {
  const [professionalExperienceToggle, setProfessionalExperienceToggle] =
    React.useState(true);

  function toggleProfessionalExperienceToggle(type) {
    setProfessionalExperienceToggle(type === "Professional");
  }
  console.log(professionalExperienceToggle);

  return (
    <div>
      <NavBar selected="Experience" />
      <div className="experience--type">
        <Button
          text="Professional"
          selected={professionalExperienceToggle}
          onClick={toggleProfessionalExperienceToggle}
        />
        <Button
          text="Academic"
          selected={!professionalExperienceToggle}
          onClick={toggleProfessionalExperienceToggle}
        />
      </div>

      {professionalExperienceToggle && (
        <ProfessionalExperience className="experience--professional" />
      )}

      {!professionalExperienceToggle && (
        <AcademicExperience className="experience--academic" />
      )}

      <Links />
    </div>
  );
}
