import "../styles/Button.css";

export default function Button({ text, selected, onClick }) {
  return (
    <button
      className={`button--main ${selected ? "selected" : ""}`}
      onClick={() => onClick(text)}
    >
      {text}
    </button>
  );
}
