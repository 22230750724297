import ExperienceCard from "./ExperienceCard";

export default function ProfessionalExperience() {
  return (
    <div className="experience--professional">
      <ExperienceCard
        company="Vyorius"
        role="Qt/QML Developer Intern"
        duration="Apr 2024 - Jun 2024"
        featuresList={[
          <li>Added features to and recreated parts of the user interface</li>,
          <li>
            <b>Created documentation</b> of third parties used in the software
          </li>,
          <li>
            <b>Software design</b> by suggesting areas needing a more user
            friendly interface
          </li>,
          <li>Maintained GitHub repositories</li>,
          <li>
            Worked on the Vyorius <b>Android app</b>
          </li>,
        ]}
      />
      <ExperienceCard
        company="Unorthodox Professionals"
        role="Game Developer Intern"
        duration="Nov 2023 - Feb 2024"
        featuresList={[
          <li>
            <b>Improved the dialog system</b> between story characters
          </li>,
          <li>
            Made it easier for non programmers to create storytelling games
          </li>,
          <li>
            <b>Added documentation</b> of the game codebase
          </li>,
        ]}
      />
      <ExperienceCard
        company="MuseScore"
        role="Open Source Contributor"
        duration="Nov 2021 - Nov 2023"
        featuresList={[
          <li>
            Made{" "}
            <a href="https://github.com/musescore/MuseScore/pulls?q=is%3Apr+author%3AHemantAntony+is%3Amerged">
              136 commits
            </a>{" "}
            (121 pull requests) from the user interface to the more complex
            backend codebase
          </li>,
          <li>
            <a href="https://github.com/musescore/MuseScore/pull/10630">
              Rewrote a dialog
            </a>{" "}
            using different technology
          </li>,
          <li>
            Completed two <b>accessibility projects</b> under the guidance of a
            mentor
          </li>,
          <li>
            Helped numerous new contributors with intent to participate in
            <b> Google Summer of Code</b> make their first pull request
          </li>,
          <li>
            Reported{" "}
            <a href="https://github.com/musescore/MuseScore/issues/created_by/HemantAntony">
              41 issues
            </a>
          </li>,
          <li>
            Gained invaluable experience in large scale software development
          </li>,
        ]}
      />
    </div>
  );
}
