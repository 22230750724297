import "../styles/ProjectCard.css";

export default function ({ image, title, desc, link }) {
  return (
    <div className="project--card">
      <img
        className="project--card--image"
        src={`./images/${image}.png`}
        alt="quizzical"
        onClick={() => window.open(link)}
      ></img>
      <div className="project--card--text">
        <a className="project--card--title" href={link}>
          {title}
        </a>
        <h2 className="project--card--desc">{desc}</h2>
      </div>
    </div>
  );
}
